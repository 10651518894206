var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-date-picker',{staticClass:"mt-4",attrs:{"elevation":"1","full-width":"","allowed-dates":_vm.allowedDates,"picker-date":_vm.pickerDate,"min":_vm.hoje(),"max":_vm.dataMax(),"color":"green"},on:{"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event},"click:date":_vm.dataEscolhida},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-row',{attrs:{"justify":"center"}},[((_vm.dataSelecionada  && _vm.tipoSelecionado === 0))?_c('horarios',{attrs:{"date":_vm.dataSelecionada,"profissional":_vm.idProfissional,"data":{
        profissional: _vm.profissional,
        especialidade: _vm.especialidade,
        idProcedimento: _vm.idProcedimento,
      }}}):(_vm.dataSelecionada  && _vm.tipoSelecionado !== 0)?_c('horario-ordem-chegada',{attrs:{"profissional":_vm.idProfissional,"date":_vm.dataSelecionada,"data":{
        profissional: _vm.profissional,
        especialidade: _vm.especialidade,
        idProcedimento: _vm.idProcedimento,
      }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }