<template>
  <v-card style="margin-top: 15px">
    <v-toolbar class="success" dark>
      <v-toolbar-title>Horários Disponíveis</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-btn-toggle tile group active-class="success">
        <v-container>
          <div v-for="(periodo, index) in horarios" v-bind:key="index">
            <v-row>
              <v-col v-if="index === 'manha' && periodo.length > 0">
                <strong>Manhã</strong>
              </v-col>
              <v-col v-else-if="index === 'tarde' && periodo.length > 0">
                <strong>Tarde</strong>
              </v-col>
              <v-col v-else-if="index === 'noite' && periodo.length > 0">
                <strong>Noite</strong>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="horario in periodo" v-bind:key="horario.Horario">
                <v-btn
                  depressed
                  style="margin-bottom: 8px"
                  :value="horario.Horario"
                  @click="horarioSelecionado"
                >
                  {{ horario.Horario }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-btn-toggle>
    </v-card-text>
  </v-card>
</template>
<script>
import BaseService from "../services/BaseService";

export default {
  name: "Horarios",
  props: ["date", "profissional", "data"],
  data: () => ({
    horarios: {
      manha: [],
      tarde: [],
      noite: [],
    },
    horariosDisponiveis: [],
  }),
  mounted() {
    this.getHorarios();
  },
  watch: {
    date() {
      this.getHorarios();
    },
  },
  methods: {
    async getHorarios() {
      if (this.date) {
        const horariosDisponiveisService = new BaseService(
          `horarios/${this.profissional}/${this.formataData(
            this.convertData(this.date, "-")
          )}`
        );
        try {
          const response = await horariosDisponiveisService.get();
          this.horariosDisponiveis = response.data.filter((data) => {
            if (!data.Block) return data;
          });
          this.horarios = {
            manha: [],
            tarde: [],
            noite: [],
          };
          this.horariosDisponiveis.map((data) => {
            const hora = data.Horario.split(":");
            if (hora[0] < 12) {
              this.horarios.manha.push(data);
              return data;
            } else if (hora[0] > 18) {
              this.horarios.noite.push(data);
            } else {
              this.horarios.tarde.push(data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    horarioSelecionado(horario) {
      localStorage.setItem("horario", JSON.stringify(horario.target.textContent.trim()));
      localStorage.setItem("dados", JSON.stringify(this.data));
      localStorage.setItem("data", JSON.stringify(this.date));
      this.$router.push({
        name: "Agendamento",
      });
    },
    convertData(date, separador) {
      if (separador === "-") return date.replace("-", "/").replace("-", "/");
      return date.replace("/", "-").replace("/", "-");
    },
    formataData(date) {
      let data = date.split("/");
      return `${data[2]}/${data[1]}/${data[0]}`;
    },
  },
};
</script>