<template>
  <v-card style="margin-top: 15px">
    <v-toolbar class="success" dark>
      <v-toolbar-title>Horários Disponíveis</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-btn-toggle tile group active-class="success">
        <v-container>
          <v-row>
              <v-col >
                <strong>O atendimento será por ordem de chegada</strong>
              </v-col>
          </v-row>
          <v-row >
              <v-col style="display:flex; justify-content:center">
                <v-btn
                  style="margin-bottom: 8px"
                  value="00:00"
                  @click="horarioSelecionado"
                >
                <v-img
                  src="../assets/IconEcon.png"
                  alt="Logo"
                  class="img"
                  width="28"
                  height="28"
                />
                  Continuar
                </v-btn>
              </v-col>
          </v-row>
          
        </v-container>
      </v-btn-toggle>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: "HorarioOrdemChegada",
  props: [ "date", "profissional", "data"],

  methods: {
    
    horarioSelecionado() {
      localStorage.setItem("horario", JSON.stringify("00:00"));
      localStorage.setItem("dados", JSON.stringify(this.data));
      localStorage.setItem("data", JSON.stringify(this.date));
      this.$router.push({
        name: "Agendamento",
        params: {
          horario: "00:00",
          dados: this.data,
          data: this.date,
        },
      });
    },
    
  },
};
</script>