<template>
  <v-container>
    <v-row justify="center">
      <v-date-picker
        v-model="date"
        elevation="1"
        full-width
        :allowed-dates="allowedDates"
        :picker-date.sync="pickerDate"
        class="mt-4"
        :min="hoje()"
        :max="dataMax()"
        color="green"
        @click:date="dataEscolhida"
      />
    </v-row>

    <v-row justify="center">
      <horarios
        v-if="(dataSelecionada  && tipoSelecionado === 0)"
        :date="dataSelecionada"
        :profissional="idProfissional"
        :data="{
          profissional: profissional,
          especialidade: especialidade,
          idProcedimento: idProcedimento,
        }"
      />
      
      <horario-ordem-chegada
        v-else-if="dataSelecionada  && tipoSelecionado !== 0"
        :profissional="idProfissional"
        :date="dataSelecionada"
        :data="{
          profissional: profissional,
          especialidade: especialidade,
          idProcedimento: idProcedimento,
        }"
      />
    </v-row>
  </v-container>
</template>
<script>
import { format, addMonths, lastDayOfMonth } from "date-fns";
import BaseService from "../services/BaseService";
import Horarios from "../components/Horarios";
import HorarioOrdemChegada from "../components/HorarioOrdemChegada";

export default {
  components: { Horarios, HorarioOrdemChegada },
  data: () => ({
    date: format(new Date(), "yyyy-MM-dd"),
    datasDisponiveis: [],
    objDatas: [],
    pickerDate: null,
    dataSelecionada: "",
    tipoSelecionado: 0,
    profissional: {},
    idProfissional: "",
    especialidade: {},
    idProcedimento: ""
  }),
  mounted() {
    if(localStorage.getItem("idProfissional"))
      this.idProfissional = localStorage.getItem("idProfissional");
    if(localStorage.getItem("profissional"))
      this.profissional = JSON.parse(localStorage.getItem("profissional"));
    if(localStorage.getItem("especialidade"))
      this.especialidade = JSON.parse(localStorage.getItem("especialidade"));
    if(localStorage.getItem("idProcedimento"))
      this.idProcedimento = JSON.parse(localStorage.getItem("idProcedimento"));
  },
  watch: {
    async pickerDate() {
      const id = this.idProfissional;
      const datasDisponiveisService = new BaseService(
        `agenda/${id}/${this.convertData(this.pickerDate, "-")}`
      );
      try {
        const response = await datasDisponiveisService.get();
        this.datasDisponiveis = response.data.filter((data) => {
          if (data.Disponivel > 0) return data;
        });
        this.datasDisponiveis = this.datasDisponiveis.map((data) => {
          if (data.Disponivel > 0) {
            this.objDatas.push({
              data: this.formataData(this.convertData(data.DataGeral, "/")),
              tipo: data.TipoHorario,
            });
            return this.formataData(this.convertData(data.DataGeral, "/"));
          }
        });
      } catch (error) {
        this.$toast.error("Aconteceu um erro! \nO agendamento será reiniciado");
        this.$router.push({ name: "Home" });
      }
    },
  },
  methods: {
    dataEscolhida(date) {
      this.tipoSelecionado = this.objDatas.find(obj=>obj.data===date).tipo;
      this.dataSelecionada = date;
    },
    hoje() {
      let date = new Date();
      return format(date, "yyyy-MM-dd");
    },
    dataMax() {
      return format(
        lastDayOfMonth(addMonths(new Date(this.hoje()), 2)),
        "yyyy-MM-dd"
      );
    },
    convertData(date, separador) {
      if (separador === "-") return date.replace("-", "/").replace("-", "/");
      return date.replace("/", "-").replace("/", "-");
    },
    formataData(date) {
      let data = date.split("-");
      return `${data[2]}-${data[1]}-${data[0]}`;
    },
    allowedDates(val) {
      return this.datasDisponiveis.indexOf(val) >= 0;
    },
  },
};
</script>